require("jquery")

import "bootstrap-material-design"
import "flatpickr/dist/flatpickr.min.css"

require("datatables.net-dt/css/jquery.dataTables.min.css");
require("datatables.net-responsive-dt/css/responsive.dataTables.min.css");
require("datatables.net-rowreorder-dt/css/rowReorder.dataTables.min.css");

import QRCode from "easyqrcodejs"
window.QRCode = QRCode

import validate from 'jquery-validation'
window.validate = validate

require("@fortawesome/fontawesome-free");

import "./material-dashboard"
import "./huboemdev"
import '../stylesheets/application'
import '../stylesheets/shared'
import '../stylesheets/mobile/mobile'
